import React, { Component} from 'react';
import { Nav, Navbar, NavbarBrand, NavbarToggler, Collapse, NavItem, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { NavLink, Link } from 'react-router-dom';


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false,
            isModalOpen: false,
            isModalOneOpen: false,
            likeList:'',
            value: '1',
            show: 'false'
        };
        this.toggleNav = this.toggleNav.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleModalOne = this.toggleModalOne.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setShow = this.setShow.bind(this);
    }

    toggleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }
    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }
    toggleModalOne() {
        this.setState({
            isModalOneOpen: !this.state.isModalOneOpen
        });
    }
    
    setShow(){
            this.setState(state =>({ show:!state.show }))
        }
    handleLogin(event) {
        alert(`Fullname: ${this.fullname.value} Phone: ${this.phone.value} Email: ${this.email.value} Date: ${this.date.value} Remember: ${this.remember.checked} Age: ${this.age.value}`);
        this.toggleModal();
        event.preventDefault();
        this.toggleModalOne();
        event.preventDefault();
    }
    handleChange(event) {
        this.setState({value: event.target.value});
    }
   
    renderLikeList = () =>{
        return (
            <div className="likes__list" >
                <ul className="unlist-modal">
                    <li><Link to="/contact" style={{color:"white"}} > Remote Support</Link></li>
                    <li outline onClick={this.toggleModalOne} style={{color:"white"}} >Get Information</li>
                    <li><Link to="/support" style={{color:"white"}} >Free Consultation</Link></li> 
                </ul>            
            </div>
        )
        }
        handleLeave=()=>{
            return this.setState({likeList:''})
        }
        handleHover=()=>{
            return this.setState({likeList:this.renderLikeList()})
        }
        componentDidMount(){
            console.log(this.state.show)
        }
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row title-brand">
                        <div  className="col-6 col-md-6 d-none d-lg-block  title-brand" style={{color:"black"}}>
                            <a role="button" style={{color:"black"}} href="https://goo.gl/maps/x14SSy57MiGW73zm8" target="_blank"><i className="fas fa fa-map-marker fa-lg" style={{color:"olivedrab"}}/> Location | </a>
                            <h8><i className="fa fa-clock-o fa-lg"/> Mon-Fri{":"}9:00-17:00 |</h8>
                            <Link to="/contact" style={{color:"black"}}> {''} (095)813-4490 </Link>
                            <i className="fa fa-search fa-lg " onClick={this.setShow}/>
                            {this.state.show ? <input placeholder="Search..." type="text" className="search-input"/>: null }
                        </div>
                        <div  className=" col-6 col-md-6 appointment1">
                            <Button  outline onClick={this.toggleModal} className="appointment">Book Appointment
                            </Button>
                        </div>
                    </div>
                </div>
                <Navbar light sticky="top" expand="md">
                    <div className="container" style={{color:"black"}}>
                        <NavbarBrand className="img-logo" href="/"><img src="./assets/images/logo1.png"/></NavbarBrand>
                        <NavbarToggler onClick={this.toggleNav} style={{color:"blue"}} /> 
                            <Collapse isOpen={this.state.isNavOpen} navbar >
                                <Nav navbar >
                                <NavItem>
                                    <NavLink className="nav-link" exact to="/home" style={{color:"black"}}> <b>Home</b>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link" to="/service" style={{color:"black"}}><b>Services</b>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link likes__wrapper" to="/support" >
                                    <div className="likes__relavance" onMouseOver={this.handleHover} onMouseLeave={this.handleLeave} style={{color:"black"}}><b>Support</b>{this.state.likeList}</div>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link" to="/contact" style={{color:"black"}}> <b>Contact </b>  
                                    </NavLink>
                                </NavItem> 
                                </Nav>
                            </Collapse>
                                <Button  outline onClick={this.toggleModalOne} className="get-quote d-none d-sm-block ">Get A Quote
                                </Button>
                        </div>
                    </Navbar> 
                <div className=" container modal">
                    <Modal className="my-modal1" isOpen={this.state.isModalOpen} toggle={this.toggleModal} size="lg" style={{maxWidth: '2000px', width: '100%', height:'1000px'}}>
                        <ModalHeader toggle={this.toggleModal}><h4 className="modalContent">Appointment</h4></ModalHeader>
                            <ModalBody>
                                <Form onSubmit={this.handleLogin}>
                                    <FormGroup>
                                        <Label htmlFor="fullName"></Label>
                                        <Input type="text" id="fullName" name="fullName" placeholder="Full Name" innerRef={input => this.fullName = input} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label htmlFor="phone"></Label>
                                        <Input type="phone" id="phone" name="phone" placeholder="Phone" innerRef= {input => this.phone= input} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label htmlFor="email"></Label>
                                        <Input type="email" id="email" name="email" placeholder="email" innerRef={input =>this.email= input} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label htmlFor="date"></Label>
                                        <Input type="date" id="date" name="date" placeholder="Date"
                                            innerRef={input =>this.date = input} />
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                        <Input type="radio"  name="remember" style={{width:"20px", height:"20px"}} innerRef={input => this.remember = input} /> I agree that my submitted data is being collected and stored.
                                        </Label>
                                    </FormGroup>
                                        <Button className="send-message mt-3" type="submit"> Submit</Button>
                                        <Button className="send-message1 mt-3 ml-2" type="cancel" value="cancel" color="secondary">Cancel</Button>
                                </Form>
                            </ModalBody>
                        </Modal>
                    </div>
                <div className=" container modal ">
                    <Modal className="my-modal" isOpen={this.state.isModalOneOpen} toggle={this.toggleModalOne} size="xl" style={{maxWidth: '2100px', width: '100%', height:'1000px'}}>
                        <ModalHeader toggle={this.toggleModalOne} ><h2 className="modalContent">GET A QUOTE</h2></ModalHeader>
                            <ModalBody>
                                <Form onSubmit={this.handleLogin}>
                                    <FormGroup>
                                    <div className= "row modalHeader">
                                        <div className= "col-sm-6"> 
                                            <h3>We Are ready to Serve you</h3><br/>
                                    <FormGroup>
                                        <Label>Choose the product:-
                                        <select value={this.state.value} onChange={this.handleChange} className="select-item"> 
                                            <option  value="default"></option>
                                            <option value="tax">Tax Preparation</option>
                                            <option value="book">Bookkeeping & Payroll</option>
                                            <option value="payroll">General liability  </option>
                                            <option value="Company Formation">Company Formation</option>
                                            <option value="other">Other</option>
                                        </select>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup> 
                                        <label >Pay as your choice :- 
                                        <select value={this.state.value} onChange={this.handleChange} className="select-item"> 
                                            <option  value=""></option>
                                            <option value="Online">Online Card Service </option>
                                            <option value="cash">Cash</option>
                                            <option value="mobile">Mobile Banking </option>
                                            <option value="check">Check</option>
                                            </select>
                                        </label>
                                    </FormGroup>
                                    <FormGroup>Contact details
                                        <Label htmlFor="fullName "></Label>
                                        <Input type="text" id="fullName" name="fullName" placeholder="Full Name"
                                                innerRef={input => this.fullName = input} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label htmlFor="phone"></Label>
                                        <Input type="phone" id="phone" name="phone" placeholder="phone"
                                                innerRef={input => this.phone= input} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label htmlFor="email"></Label>
                                        <Input type="email" id="email" name="email" placeholder="email"
                                        innerRef={input =>this.email= input} />
                                    </FormGroup>
                                    
                                    <FormGroup>
                                        <Label htmlFor="age"></Label>
                                        <Input type="age" id="age" name="age" placeholder="age"
                                        innerRef={input =>this.age= input} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label check className="ml-3 mt-2">
                                        <Input type="radio"  name="remember"  
                                            innerRef={input => this.remember = input}/> I agree that my submitted data is being collected and stored.
                                        </Label>
                                    </FormGroup>
                                        <Button className="send-message  mt-3" type="submit">Submit</Button>
                                        <Button className="send-message1 mt-3 ml-2" type="cancel" value="cancel">Cancel</Button>
                                        
                                    </div>
                                    <div className= "col-sm-6 Quote-image">
                                    <img  src="./assets/images/portal1.jpeg"/>
                                    </div>
                                    </div>
                                    </FormGroup>
                                </Form>
                            </ModalBody>
                    </Modal>
                </div>
            </React.Fragment>
        );
    }
}
export default Header;
