export const sliderData = [
    {
      image: "/assets/images/umberella.jpeg",
      heading: "We are helping you receive all your covid relief funding",
      desc: "Employe Retention credit ERC.",
    },
    {
      image: "/assets/images/slide-4.jpeg",
      heading: "File An Extension Today",
      desc: "Extension of Time To File Your .... Tax",
    },
    {
      image: "/assets/images/valuation.jpeg",
      heading: "Start preparing your return for free",
      desc:  "Helping you organize your ...... finances",
    },
  ];